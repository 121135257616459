import React, { useEffect } from "react";
import styled from "styled-components";

import FetchingClickShield from "js/react/components/FetchingClickShield";
import { Authenticate } from "js/react/views/Auth/Authenticate";

const Container = styled.div`
    height: 100%;
    width: 100%;
    
    background-color: #f8fafc;
`;

function AuthenticatedHandler() {
    useEffect(() => {
        Office.context.ui.messageParent(JSON.stringify({
            eventType: "close"
        }));

        // Uncomment code below and remove code above to redirect to
        // choose plan page instead of closing the dialog
        //
        // window.location.href = "/ppt-addin/choosePlan";
    }, []);

    return (
        <FetchingClickShield fetching={true} backgroundColor="#f8fafc" />
    );
}

function PPTAddinAuthenticate({ match: { params: { page } } }) {
    return (
        <Container>
            <Authenticate defaultPage={page ?? "signIn"} >
                <AuthenticatedHandler />
            </Authenticate>
        </Container>
    );
}

export default PPTAddinAuthenticate;
