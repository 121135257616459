import React, { Component } from "react";
import { DialogTitle, Fade, Grid } from "@material-ui/core";
import styled from "styled-components";

import db from "js/db";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { StandaloneDialogContent, StandaloneForm, StandalonePage } from "../Onboarding/Standalone";
import PlanCard from "./Components/PlanCard";

const Container = styled.div`
    background: #F8FAFC;
`;

const StyledStandaloneForm = styled.div`
    &&& {
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;
    }
`;

const StyledGridContainer = styled(Grid)`
    gap:20px;
`;

const StyledStandaloneDialogContent = styled(StandaloneDialogContent)`
    padding: 20px 20px !important;
`;

class PPTAddinChoosePlan extends Component {
    state = {
        fadeOut: false,
        ready: false,
        hideBasic: false,
        emphasizePlan: "pro",
        hasTakenTrial: false,
        hasTakenTeamTrial: false
    };

    componentDidMount() {
        const searchParams = new URLSearchParams(location.search);
        this.setState({
            hideBasic: searchParams.get("hideBasic") === "true",
            emphasizePlan: searchParams.get("emphasizePlan") ?? "pro",
            hasTakenTrial: searchParams.get("hasTakenTrial") === "true",
            hasTakenTeamTrial: searchParams.get("hasTakenTeamTrial") === "true",
            ready: true
        });
    }

    saveConfirm = () => db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}`).update({ didConfirmPlan: true });

    handlePro = async () => {
        await this.saveConfirm();

        this.setState({ fadeOut: true });

        setTimeout(() => {
            window.location = "/ppt-addin/checkout?plan=pro&billingTerm=year";
        }, 750);
    }

    handleTeam = async () => {
        await this.saveConfirm();

        this.setState({ fadeOut: true });

        setTimeout(() => {
            window.location = "/ppt-addin/checkout?plan=team&billingTerm=year";
        }, 750);
    }

    handleBasic = async () => {
        await this.saveConfirm();

        this.setState({ fadeOut: true });

        setTimeout(() => {
            Office.context.ui.messageParent(JSON.stringify({
                eventType: "close"
            }));
        }, 750);
    }

    render() {
        const { fadeOut, hideBasic, hasTakenTrial, hasTakenTeamTrial, emphasizePlan } = this.state;

        const planCardProps = [
            {
                hide: hideBasic,
                icon: "person",
                title: "Starter Plan",
                planName: "Basic",
                planPrice: "Access limited features for free",
                buttonId: "choose-basic",
                buttonOnClick: this.handleBasic,
                buttonText: "Get started",
                planMore: "The Beautiful.ai basics:",
                features: ["Smart Slide templates", "Gallery of pre-designed slides"]
            },
            {
                emphasize: emphasizePlan === "pro",
                icon: "stars",
                title: "For Professionals",
                planName: "Pro",
                planPrice: "From $12/mo",
                buttonId: "choose-pro",
                buttonOnClick: this.handlePro,
                buttonText: hasTakenTrial ? "Upgrade" : "Try it free",
                planMore: "Everything in Basic, plus:",
                features: ["Save editable PPT slides", "AI slide and image generation", "AI text assistant"]
            },
            {
                emphasize: emphasizePlan === "team",
                icon: "apartment",
                title: "For companies",
                planName: "Team",
                planPrice: "From $40/mo per user",
                buttonId: "choose-team",
                buttonOnClick: this.handleTeam,
                buttonText: hasTakenTeamTrial ? "Upgrade" : "Try it free",
                planMore: "Everything in Pro, plus:",
                features: ["Custom Company Theme", "Centralized slide library", "Slide asset library"]
            }
        ];

        return (
            <Container>
                <StandalonePage maxWidth={800}>
                    <Fade in={!fadeOut} timeout={750}>
                        <StandaloneForm
                            style={{
                                height: "530px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "25px"
                            }}
                            open={true}
                            fullWidth >
                            <StyledStandaloneForm>
                                <DialogTitle align="center" style={{ paddingBottom: 0 }}>
                                    Choose the plan that's right for you
                                </DialogTitle>
                                <StyledStandaloneDialogContent>
                                    <StyledGridContainer container >
                                        {planCardProps.map((props, index) => <PlanCard key={index} {...props} />)}
                                    </StyledGridContainer>
                                </StyledStandaloneDialogContent>
                            </StyledStandaloneForm>
                        </StandaloneForm>
                    </Fade>
                </StandalonePage>
            </Container>
        );
    }
}

export default withFirebaseUser(PPTAddinChoosePlan);
