import React from "react";
import styled from "styled-components";

import { getStaticUrl } from "js/config";

const Conainer = styled.div`
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const IframeBaiVideo = styled.iframe`
    width: 100%;
`;

function PPTAddinVideo() {
    return (<Conainer>
        <IframeBaiVideo
            allow="autoplay; fullscreen; picture-in-picture"
            src="https://player.vimeo.com/video/915668463?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&loop=1&muted=1&background=1&pip=0&controls=0"
            title="The presentation software for everyone."
        ></IframeBaiVideo>
    </Conainer>);
}

export default PPTAddinVideo;
