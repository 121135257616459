import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import FetchingClickShield from "js/react/components/FetchingClickShield";

// IMPORTANT: if you're making changes to any of the asynchronous imports below, make sure you test
// them locally with the string-replace-loader loader disabled in webpack.dev.config.js

const LoadableCheckout = loadable(() => import(/* webpackChunkName: "pptAddingCheckout" */ "js/app/Checkout"), {
    fallback: <FetchingClickShield opaque visible />
});

const Container = styled.div`
    background: #F8FAFC;
`;

function PPTAddinCheckout() {
    const [isReady, setReady] = useState(false);
    const [checkoutProps, setCheckoutProps] = useState({
        plan: null,
        enterpriseInviteId: null,
        billingInterval: null,
        priceId: null,
        displayName: null,
        quantity: null
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setCheckoutProps({
            plan: searchParams.get("plan"),
            enterpriseInviteId: searchParams.get("enterpriseInviteId"),
            billingInterval: searchParams.get("billingTerm"),
            priceId: searchParams.get("price"),
            displayName: searchParams.get("displayName"),
            quantity: searchParams.get("quantity")
        });
        setReady(true);
    }, []);

    const handleCloseDialog = () => {
        Office.context.ui.messageParent(JSON.stringify({
            eventType: "close"
        }));
    };

    return (<Container>
        {isReady && <LoadableCheckout {...checkoutProps} closeDialog={handleCloseDialog} />}
    </Container>);
}

export default PPTAddinCheckout;
