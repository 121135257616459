import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { Logout } from "client-app/Logout";
import { browserHistory } from "js/react/history";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import theme from "js/react/materialThemeOverrides";
import { AuthConsumer } from "js/react/views/Auth/AuthConsumer";
import { FirebaseAuthProvider } from "js/react/views/Auth/FirebaseAuthContext";

import { app } from "js/namespaces";
import { getCanvasBundle } from "js/canvas";
import appController from "js/core/AppController";
import UndoManager from "js/core/services/undoManager";
import { ThemeManager } from "js/core/themeManager";

import { ds } from "js/core/models/dataService";
import { SharedThemes } from "js/core/models/sharedTheme";
import { BuiltInThemes, UserThemes } from "js/core/models/theme";
import PPTAddinAuthenticate from "./PPTAddinAuthenticate";
import PPTAddinCheckout from "./PPTAddinCheckout";
import PPTAddinChoosePlan from "./PPTAddinChoosePlan";
import PPTAddinCommands from "./PPTAddinCommands";
import PPTAddinVideo from "./PPTAddinVideo";

import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.PPT_ADDIN);

// IMPORTANT: if you're making changes to any of the asynchronous imports below, make sure you test
// them locally with the string-replace-loader loader disabled in webpack.dev.config.js

const LoadablePPTAddinTaskpane = loadable(() => import(/* webpackChunkName: "pptAddinTaskpane" */ "js/react/views/PPTAddin/PPTAddinTaskpane"), {
    fallback: <FetchingClickShield backgroundColor="#000000" visible />
});

const LoadablePPTAddinEditor = loadable(() => import(/* webpackChunkName: "pptAddinEditor" */ "js/react/views/PPTAddin/PPTAddinEditor"), {
    fallback: <FetchingClickShield opaque visible />
});

function PPTAddin() {
    const [isReady, setReady] = useState(false);

    useEffect(() => {
        logger.info("[PPTAddin] mounted");

        // This is an ugly hack that allows presentation model to pull the correct
        // implementations
        ds.prepare({ SharedThemes, BuiltInThemes, UserThemes });
        app.themeManager = new ThemeManager();
        app.getCanvasBundle = getCanvasBundle;
        app.undoManager = new UndoManager();
        app.appController = appController;

        Office.initialize = () => { };
        Office.onReady(() => setReady(true));
    }, []);

    if (!isReady) {
        return <div></div>;
    }

    return (
        <FirebaseAuthProvider>
            <AuthConsumer>
                {() => (
                    <MuiThemeProvider theme={theme}>
                        <Router history={browserHistory}>
                            <Switch>
                                <Route path="/ppt-addin/commands" render={() => <PPTAddinCommands />} />
                                <Route path="/ppt-addin/authenticate/:page" render={props => <PPTAddinAuthenticate {...props} />} />
                                <Route path="/ppt-addin/video" render={() => <PPTAddinVideo />} />
                                <Route path="/ppt-addin/logout" render={() => <Logout />} />
                                <Route path="/ppt-addin/choosePlan" render={() => <PPTAddinChoosePlan />} />
                                {/* Heavy views are loaded asynchronously */}
                                <Route path="/ppt-addin/checkout" render={() => <PPTAddinCheckout />} />
                                <Route path="/ppt-addin/editor" render={() => <LoadablePPTAddinEditor />} />
                                <Route path="/ppt-addin/taskpane" render={() => <LoadablePPTAddinTaskpane />} />
                            </Switch>
                        </Router>
                    </MuiThemeProvider>
                )}
            </AuthConsumer>
        </FirebaseAuthProvider>
    );
}

export default PPTAddin;
